import Sortable from '@/components/sortable';
import uploadFile from '@/data/uploadFile';
import { useWindowDropZone } from '@/providers/dropzone';
import { Box, Button, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import { forwardRef, Fragment, useEffect } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Attachment from './attachment';

const Input = styled( 'input' )( { display: 'none' } );

export type AttachmentInputProps = {
	files?: string[],
	setFiles: ( files: any[] ) => void,
	placeHolder?: string | ReactNode,
	width?: number | string,
	disableUpload?: boolean,
	maxMB?: number,
	hasButton?: boolean,
	renderButton?: ( onClick, replaceFile ) => ReactNode
} & DropzoneOptions;

function AttachmentInput( {
	files,
	setFiles,
	placeHolder = 'Drag \'n\' drop some files here, or click to select files',
	width = '100%',
	disableUpload,
	maxMB = 10,
	hasButton,
	renderButton = ( onClick, replaceFile ) => (
		<Box
			sx={{
				width       : '100%',
				border      : '2px dashed',
				borderColor : 'divider',
				borderRadius: 2,
				textAlign   : 'center',
				p           : 2,
			}}>
			<Typography color='colors.info.hover'>Drag & Drop</Typography>
			<Button variant='outlined' sx={{ mt: 1 }} onClick={onClick}>
				{replaceFile ? 'Replace File' : 'Choose File'}
			</Button>
		</Box>
	),
	...props
}: AttachmentInputProps, ref ) {
	
	const { enqueueSnackbar } = useSnackbar();
	const { getDropZoneProps } = useWindowDropZone();
	const { getRootProps, getInputProps, open, isDragActive } = useDropzone( {
		// Disable click and keydown behavior
		...props,
		noClick : hasButton,
		maxFiles: +!props.multiple,
		onDrop  : async ( newFiles ) => {
			if ( newFiles.some( ( file ) => file.size > ( props?.maxSize || maxMB * 1024 * 1024 ) ) ) {
				enqueueSnackbar( `File size too large, max ${maxMB} MB` );
				if ( !props.multiple === false ) return;
			}
			
			const newFileInput = await Promise.all( newFiles
				.filter( ( file ) => file.size <= props?.maxSize || 5242880 )
				.map( ( file ) => disableUpload ? file : uploadFile( file ) ) );
			if ( isEmpty( newFileInput ) ) {
				enqueueSnackbar( 'File size is too large or invalid file type.', { variant: 'error' } );
				return;
			}
			setFiles( props.multiple ? [ ...files || [], ...newFileInput ] : newFileInput );
		},
		noKeyboard: true,
	} );
	
	useEffect( () => {
		getDropZoneProps( hasButton ? {
			getRootProps,
			getInputProps,
			isDragActive,
			hasButton,
			open,
		} : null );
	}, [ hasButton, isDragActive ] );
	
	const onDelete = ( url: string ) => setFiles( files.filter( ( file ) => file !== url ) );
	
	const dropLocation = hasButton ? (
		<Fragment>
			<Input ref={ref as any} {...getInputProps()}/>
			{renderButton?.( open, !props.multiple && files?.length > 0 )}
		</Fragment>
	) : (
		<Box
			{...getRootProps()}
			sx={{
				width,
				'border'      : '2px dashed',
				'borderColor' : 'divider',
				'borderRadius': 2,
				'textAlign'   : 'center',
				'p'           : 2,
				'transition'  : '.3s',
				'bgcolor'     : isDragActive ? 'rgb(19, 57, 97)' : '',
				'color'       : 'text.secondary',
				':hover'      : {
					color      : 'primary.main',
					bgcolor    : !isDragActive && 'alpha.primary',
					borderColor: 'primary.main',
					cursor     : !isDragActive && 'pointer',
				},
			}}>
			<Input ref={ref as any} {...getInputProps()}/>
			<Typography component='span'>
				{placeHolder}
			</Typography>
		</Box>
	);
	
	if ( !files?.length ) return dropLocation;
	
	return (
		<Fragment>
			{dropLocation}
			{props.multiple && Boolean( files.length ) && (
				<Box mt={2}>
					<Sortable
						items={files?.map( ( file ) => ( { id: file } ) )}
						setItems={( files ) => setFiles( files.map( ( { id } ) => id ) )}
						renderItem={( { item, handle } ) => (
							<Attachment
								removeDownload
								sx={{ mr: 1, mb: 1 }}
								handle={handle}
								src={item.id}
								imageSX={{ objectFit: 'cover' }}
								onDelete={onDelete}
							/>
						)}
					/>
				</Box>
			)}
		</Fragment>
	);
}

export default forwardRef( AttachmentInput ) as typeof AttachmentInput;
